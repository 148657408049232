<template>
  <div id="my-page">
    <AppContainer :useBackground="true" :useShadow="true">
      <router-view></router-view>
    </AppContainer>
  </div>
</template>

<script>
export default {
  name: "MyPageLayout"
};
</script>

<style></style>
